import React from 'react';
import { css, cx } from '@emotion/react'
import theme from '../theme';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Faq = ({ children, ...props }) => (
    <div>
        <Accordion sx={{boxShadow: "none !important"}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>Will my child do well in school Mathematics?</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            Academic improvement is a straightaway change that we see in students who join our course. On top of academic improvement, our courses develop children’s computational abilities and thinking patterns.
            </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow: "none !important"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>Is this similar to ABACUS or Vedic Maths?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    The speed math techniques that we teach do not involve any tools, tricks or shortcuts. Children will stop using pens and paper for calculations by learning organic methods with us.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow: "none !important"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>How is this different from coding classes?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                Becoming an ingenious coder is close to impossible without understanding the Mathematical nuances behind coding, and we exactly teach that in our courses.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow: "none !important"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>Will my child become the next human calculator?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Becoming the next human calculator depends on a lot of other external factors but we can undoubtedly assure you that this is the right place to begin that journey.
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow: "none !important", borderTop: "none", borderBottom: "1px solid #e7e7e7"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>Did Neelakantha Bhanu design the courses?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                After years and years of research by collecting over 20000 data points, the Fastest Human Calculator Neelakantha Bhanu exactly nailed down the essence of how each child is capable of learning Math through these courses.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </div>
);

export default Faq;
