import * as React from 'react';
import Typography from '@mui/material/Typography';
import theme from '../theme'


const band = {
  background: "transparent linear-gradient(180deg, #DE9FFF 0%, #331643 100%) 0% 0% no-repeat padding-box",
  padding: "30px",
  color: "#fff",
  fontSize: "2.3em",
  fontWeight: "bold",
  mb: "100px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    fontSize: "1.3em",
    mb: "50px"
  }
}

export default function Band(props) {
    return (
        <Typography component="h2" sx={band} align="center" {...props}>
            {props.content}
        </Typography> 
    );
}
