import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { css } from '@emotion/react'
import learnIllus1 from '../static/images/basic-arithmetic-operations.svg'
import learnIllus2 from '../static/images/Visualisation-and-Computation-Techniques.svg'
import learnIllus3 from '../static/images/exploring-the-world-of-geometry.svg'
import learnIllus4 from '../static/images/exploring-the-world-around-you-with-math.svg'
import learnIllus5 from '../static/images/learning-the-math-behind-AI.svg'
import learnIllus6 from '../static/images/introduction-to-abstract-math.svg'
import learnIllus7 from '../static/images/how-top-recomends-works.svg'
import learnIllus8 from '../static/images/understand-the-world-of-data-science.svg'

const illus2 = css`
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  marginBottom: 20px;
`

const illusContainer = css`
  height: 200px;
  position: relative;
`

export default function WhatStudentsLearn({xs=6, sm=6, md=3}) {
  return (
    <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h3" align="center">
        What Students Will Learn
        </Typography><br/>
        <Grid container spacing={4}>
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus1} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Basic Arithmetic operations
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus2} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Visualization and Computation Techniques
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus3} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Exploring the world of Geometry
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus4} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Exploring the world around you with Math
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus5} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Learning the Math behind AI
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus6} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Introduction to Abstract Math
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus7} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            How top Recommends Work
            </Typography>
        </Grid> 
        <Grid item xs={xs} sm={sm} md={md}>
            <div css={css`${illusContainer};`}>
            <img src={learnIllus8} css={css`${illus2};`}/>
            </div>
            <Typography variant="subtitle2" align="center">
            Understanding the World of Data Science
            </Typography>
        </Grid> 
        </Grid>
    </Grid>
  );
}