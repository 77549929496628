import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme'
import Header from '../components/Header';
import Container from '@mui/material/Container';
import 'react-intl-tel-input/dist/main.css';
import DefaultTemplate from '../templates/DefaultTemplate';
import SlotBookingService from '../services/slotBookingService';
import OtpInput from 'react-otp-input';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Moment from 'react-moment'
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import bookingConfirmedImg from '../static/images/booking-confirmed.svg'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MuiPhoneNumber from 'material-ui-phone-number';

function TermsAndCondition(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'By Clicking this, you agree to our  '}
      <Link href="/" color={theme.palette.primary.main}>
        Terms and Condition
      </Link>
    </Typography>
  );
}

export default function BookFreeDemo(props) {

  const componentId = "DefaultTemplate" 

  const urlParam = function (name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(props.location.href);
    if (results===null) {
    return null;
    }
    return decodeURI(results[1]) || 0;
  }

  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState(null)
  const [age, setAge] = useState('')
  const [slots, setSlots] = useState([])
  const [slotDays, setSlotDays] = useState([])
  const [selectedDay, setDay] = useState('')
  const [time, setTime] = useState('')
  const [step, setStep] = useState(1)
  const [showOtp, setShowOtp] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [otpTimer, setOtpTimer] = useState({minutes: 1, seconds: 59 })
  const [phoneInvalid, setPhoneInvalid] = useState(false)
  // const [countDownTime, setCountDown] = useState(new Date().getTime() + 120000)
  const [enableResendOtpButton, setEnableResendOtpButton] = useState(true)
  const [defaultCountry, setDefaultCountry] = useState('in')

  useEffect(() => {
    setOpenForm(urlParam("openForm") === 'true')
    setDefaultCountry('in')
  },[]);

  const [otpData, setOtpData] = useState({
    parent_name: null,
    mobile_number: null,
    email_address: null,
    source: urlParam("utm_source"),
    source_campaign: urlParam("utm_campaign"),
    source_medium: urlParam("utm_medium"),
    source_content: urlParam("utm_content"),
    country: "India"
  })

  const handleOtp = () => {
    setShowOtp(true)
    const countDownTime = new Date().getTime() + 120000
    resendInterval(countDownTime)
    SlotBookingService.getOTP(otpData)
    .catch(err => console.log(err));
  }

  const resendInterval = (countDownTime) => {
    var timer = setInterval(function() {
      var now = new Date().getTime();
      var distance = (countDownTime - now);
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if(distance >= 0){
        setOtpTimer({minutes, seconds})
      }  else {
        setEnableResendOtpButton(false)
        clearInterval(timer);
      }
    }, 1000);
  }

  const handleResendOtp = () => {
    const countDownTime = new Date().getTime() + 300000
    resendInterval(countDownTime)
    SlotBookingService.resendOTP({mobile_number: otpData.mobile_number}).then(_ => setEnableResendOtpButton(true))
    .catch(err => console.log(err));
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(step == 1) {
      if(otpData.mobile_number){
        handleOtp()
      }else{
        setPhoneInvalid(true)
      }
    } else if(step == 3) {
      bookSlot()
    }
  };

  const parseSlots = (rawSlotsData) => {
    let days = []
    setSlots(rawSlotsData.map(slot => {
      const slotDate = slot.slot_start_time.split("-")
      let day = slotDate[2]+"-"+slotDate[1]+"-"+slotDate[0]
      if(days.indexOf(day) == -1){
        days.push(day)
      }
      return slotDate[2] + "-" + slotDate[1] + "-" + slotDate[0] + "T" + slotDate.slice(3,6).join(":")
    }))
    setTime(slots[0])
    setDay(days[0])
    setSlotDays(days)
  }

  const selectAge = (selectedAge) => {
      setAge(selectedAge)
      setStep(3)
      setIsLoading(true)
      SlotBookingService.getSlots({
        mobile_number: otpData.mobile_number.replace(/-|\s/g,""),
        student_age: selectedAge,
        time_zone: "Asia/Kolkata",
        request_token: "dummy"
      }).then(data => {
        parseSlots(data.slots)
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
      })    
  }

  const otpChange = (otp) => {
    setOtp(otp)
    if(otp.toString().length == 6){
      SlotBookingService.verifyOTP(
        {
          "mobile_number":otpData.mobile_number,
          "otp":otp
        }
      ).then(data => {
        if(data.otp_verified){
          setStep(2)
        }else {
          setOtpError("Incorrect OTP, Try again!")
        }
      })
    }
  }

  const bookSlot = () => {
    const slotTime = new Date(time)
    SlotBookingService.bookSlot({
      "mobile_number": otpData.mobile_number,
      "slot_start_time":{
         "day": slotTime.getDate(),
         "month": slotTime.getMonth() + 1,
         "year": slotTime.getFullYear(),
         "hour": slotTime.getHours(),
         "minute": slotTime.getMinutes(),
         "second": slotTime.getSeconds()
      },
      "time_zone":"Asia/Kolkata",
      "student_age":age,
      "request_token":"dummy"
    }).then(data => {
      setStep(4)
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container maxWidth="xl" fixed sx={{px:{xs:0}}}> 
        <Grid container component="main" sx={{ height: '100vh' }} spacing="10">
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              maxHeight: "90vh",
              overflow: "auto"
            }}
          >
          <DefaultTemplate openForm={openForm} />
          </Grid>  
          <Grid item xs={false} sm={false} md={6} sx={{
            [theme.breakpoints.down('sm')]:{
              position: "fixed",
              bottom: openForm?0:"-540px",
              left:0,
              right:0,
              pl: "0 !important"
            }
          }}>
            <Box
              sx={{
                my: 5,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#FFEFF7',
                py: 8,
                px: 6,
                border: '1px solid #FFCBE5',
                borderRadius: '2rem',
                position: 'relative',
                height: '620px',
                [theme.breakpoints.down('sm')]:{
                  margin: 0,
                }
              }}
            >
            {openForm ? <CloseIcon onClick={(e) => setOpenForm(false)} sx={{ 
              position: "absolute",
              top: 20,
              right: 20
            }}/>  : (<Button
            onClick={(e) => setOpenForm(true)}
            fullWidth
            variant="contained"
            color="secondary"
            size="md"
            sx={{ 
              backgroundImage: 'linear-gradient(to bottom, #FD627C, #E42B49)',
              padding: "0.5rem 2rem",
              borderRadius: "15px",
              width: '300px',
              display: 'block',
              mt: "-50px",
              mb: "20px",
              [theme.breakpoints.up('sm')]:{
                display: "none"
              },
            }}
          >
            Book a Free Class
          </Button>)}
            <Box component="form" onSubmit={handleSubmit}>  
              {step == 1 ? (<div>  
                <Typography component="h2" variant="h2" sx={{display: {xs: "none"}}}>
                  Book a FREE class for your child!
                </Typography>
                <Typography component="h3" variant="h3" sx={{display: {xs: "block"}}}>
                  Book a FREE class for your child!
                </Typography>
                <br/>
                <TextField
                  margin="normal"
                  fullWidth
                  name="parent_name"
                  label="Parent Name"
                  type="text"
                  id="parent_name"
                  value={otpData.parent_name}
                  onChange={(e) => setOtpData({...otpData, parent_name: e.target.value})}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  type="email"
                  id="email_address"
                  label="Email Address"
                  name="email_address"
                  autoComplete="email"
                  value={otpData.email_address}
                  onChange={(e) => setOtpData({...otpData, email_address: e.target.value})}
                />
                <MuiPhoneNumber
                  error={phoneInvalid}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  type="tel"
                  id="mobile_number"
                  autoComplete="phone"
                  value={otpData.mobile_number}
                  defaultCountry={defaultCountry} 
                  onChange={(number) => setOtpData({...otpData, mobile_number: number.replace(/-|\s/g,"")})}
                />
                <div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ 
                      backgroundImage: 'linear-gradient(to bottom, #FD627C, #E42B49)',
                      padding: "1.2rem 2rem",
                      borderRadius: "15px",
                      width: '300px',
                      margin: '50px auto 10px',
                      display: 'block'
                    }}
                  >
                    Book a Free Class
                  </Button>
                  <TermsAndCondition />
                  {showOtp ? <Card sx={{
                    width: '70%',
                    margin: '0 15%',
                    left: 0,
                    position: 'absolute',
                    top: 150,
                    padding: '30px',
                    zIndex: 100,
                    borderRadius: '20px',
                    [theme.breakpoints.down('sm')]:{
                      width: "90%",
                      margin: "0 5%"
                    }
                  }}>
                    <CardContent>
                      <Typography variant="h3" color="text.secondary" align="center" gutterBottom>
                        Enter the <br/>6-digit OTP
                      </Typography>
                      
                      { otpError ? <Typography align="center" sx={{color:"#e02b0e"}}>{otpError}</Typography> :
                      <Typography variant="body2" color="text.secondary" align="center" gutterBottom>
                        An otp has been sent to your mobile number
                      </Typography>}

                      <OtpInput
                        type="number"
                        value={otp}
                        onChange={otpChange}
                        numInputs={6}
                        containerStyle={{
                          justifyContent: "center"
                        }}
                        inputStyle={{
                          padding: "5px",
                          border: "none",
                          borderBottom: "2px solid #777",
                          margin: "10px",
                          fontSize: "1rem",
                          width: "100%"
                        }}
                        errorStyle={{
                          borderBottom: "2px solid #e02b0e"
                        }}
                        shouldAutoFocus
                        isInputNum
                        hasErrored={otpError}
                      />
                      <Typography align="center" variant="body2" color="#DF2A8B" gutterBottom>{otpTimer.minutes + ":" + otpTimer.seconds}</Typography>
                      <br/>
                      <Typography variant="body2" align="center" gutterBottom>Didn't recieve the OTP?</Typography>
                      <Button 
                        onClick={(e) => handleResendOtp(false)} 
                        variant="outlined" 
                        sx={{width: "150px", margin: "0 auto", display: "block"}} 
                        disabled={enableResendOtpButton}>
                          Resend OTP 
                      </Button>
                      <CloseIcon onClick={(e) => setShowOtp(false)} sx={{ 
                        position: "absolute",
                        top: 20,
                        right: 20
                      }}/>
                    </CardContent>
                  </Card>:""}
                </div>
              </div>) : step == 2 ? (<div>
                <Typography variant="h2" align="center" gutterBottom>
                  Select the Age of your child
                </Typography>
                <Typography align="center" color="#6F6F6F" gutterBottom>
                  Just one step away from booking your slot!
                </Typography>
                <Grid container spacing="4" sx={{
                    padding: "20px 60px",
                    [theme.breakpoints.down('sm')]:{
                      padding: "20px 0",
                    }
                  }}>
                  {Array(12).fill().map((_, idx) => 
                    (<Grid item xs={3} key={idx}>
                    <Box sx={{
                      background: "#fff",
                      border: "1px solid #AFAFAF",
                      width: 70, height: 70,
                      borderRadius:"16px",
                      padding: "10px 16px",
                      mb: "20px",
                      cursor: "pointer",
                      [theme.breakpoints.down('sm')]:{
                        width: 70,
                        height: 70,
                        padding: 1.2
                      },
                      "&:hover": {
                        borderColor: "#DA1B88",
                        color: "#DA1B88"
                      }
                      }} onClick={(e) => selectAge(6 + idx)}>
                        <Typography variant="h4" align="center">
                          {6 + idx != 17 ? 6 + idx : "16+"}
                        </Typography>
                        <Typography variant="caption" align="center" sx={{display: "block"}}>
                          years
                        </Typography> 
                    </Box></Grid>)
                  )}
                </Grid>
              </div>): step == 3 ? ( <div>
                <Typography variant="h2" align="center" gutterBottom sx={{mb:5, display: {xs: "none", md: "block"}}}>
                  Book a slot for your child
                </Typography>
                {isLoading ? (<Card sx={{ display: 'flex', padding: "10px", borderRadius: "10px", height: "130px", padding: "50px" }}>
                  <CircularProgress />
                  <Typography align="center" sx={{mt: "5px", ml: "10px"}}>Fetching slots...</Typography>
                </Card>) : <div>
                <Typography gutterBottom>
                  Select Date
                </Typography>
                <Grid container spacing="4">
                  {slotDays.map((day, idx) => 
                    (<Grid item xs={3} md={3} key={idx}>
                    <Box className={day == selectedDay? "active":"deactive"} sx={{
                      background: "#fff",
                      border: "1px solid #AFAFAF",
                      width: 70, height: 70,
                      borderRadius:"16px",
                      padding: "14px 10px",
                      mb: "10px",
                      cursor: "pointer",
                      "&:hover, &.active": {
                        borderColor: "#DA1B88",
                        color: "#DA1B88"
                      }
                      }} onClick={(e) => setDay(day)}>
                      <Typography variant="h5" align="center">
                        <Moment date={day} format="ddd" />
                      </Typography>
                      <Typography variant="caption" align="center" sx={{display: "block"}}>
                       <Moment date={day} format="DD MMM" />
                      </Typography>
                    </Box>
                    </Grid>)
                  )}
                </Grid>
                <br/>
                <Typography gutterBottom>
                  Select Time
                </Typography>
                <Grid container spacing="20">
                  {slots.filter((slot) => new Date(slot).getDay() == new Date(selectedDay).getDay()).map((slot, idx) =>
                    (<Grid item xs={3} key={idx}>
                    <Box className={slot == time? "active":"deactive"} sx={{
                      background: "#fff",
                      border: "1px solid #AFAFAF",
                      width: "100%",
                      borderRadius:"8px",
                      padding: "5px 10px",
                      mb: "10px",
                      cursor: "pointer",
                      "&:hover, &.active": {
                        borderColor: "#DA1B88",
                        color: "#DA1B88"
                      }}}
                      onClick={(e) => setTime(slot)}>
                      <Typography variant="body1" align="center">
                        <Moment date={slot} format="LT" />
                      </Typography> 
                      </Box>
                    </Grid>)
                  )}
                </Grid> 
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ 
                      backgroundImage: 'linear-gradient(to bottom, #FD627C, #E42B49)',
                      padding: "1.2rem 2rem",
                      borderRadius: "15px",
                      width: '300px',
                      margin: '50px auto 10px',
                      display: 'block'
                    }}
                  >
                    Book a Free Class
                  </Button> 
                </div>}  
              </div>): (
                <div>
                  <Card sx={{px: {xs:8, md:8}, py: 2, borderRadius: "20px"}}>
                    <img src={bookingConfirmedImg}/>
                    <Typography variant="h3" align="center">Congratulations</Typography>
                    <Typography variant="body2" color="#aaa" align="center">
                      Your slot has been booked. You can attend it along with your child.
                    </Typography>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="right">time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                <Moment date={time} format="ddd DD MMM" />
                              </TableCell>
                              <TableCell align="right"><Moment date={time} format="LT" /></TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>  
                  <Button
                    type="submit"
                    fullWidth
                    size="medium"
                    variant="contained"
                    color="secondary"
                    href="/"
                    sx={{ 
                      backgroundImage: 'linear-gradient(to bottom, #FD627C, #E42B49)',
                      
                      borderRadius: "15px",
                      margin: '30px auto 10px'
                    }}
                  >
                    Go to Home
                  </Button> 
                </div>  
              )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}